html,
body {
	height: 100%;
	font-size: 12px;
	-webkit-font-smoothing: unset !important;
	/*letter-spacing: 1px;*/
}

main {
	min-height: 100vh;
}

.bg-white {
	background: $white;
}
.text-white {
	color: $white;
}

.bg-navy {
	background: $navy;
}
.text-navy {
	color: $navy;
}

.bg-darkNavy {
	background: $darkNavy;
}
.text-darkNavy {
	color: $darkNavy;
}

.bg-cyan {
	background: $cyan;
}
.text-cyan {
	color: $cyan;
}

.bg-blue {
	background: $blue;
}
.text-blue {
	color: $blue;
}

.bg-purple {
	background: $purple;
}
.text-purple {
	color: $purple;
}

.bg-paleBlue {
	background: $paleBlue;
}
.text-paleBlue {
	color: $paleBlue;
}

.bg-navGrey {
	background: $navGrey;
}
.text-navGrey {
	color: $navGrey;
}

.bg-pageBackGround {
	background: $pageBackGround;
}
.text-pageBackGround {
	color: $pageBackGround;
}

.bg-textGrey {
	background: $textGrey;
}
.text-textGrey {
	color: $textGrey;
}

.bg-logoutGrey {
	background: $logoutGrey;
}
.text-logoutGrey {
	color: $logoutGrey;
}

.text-red {
	color: #a94442 !important;
}
.text-bright-red {
	color: red;
}

.outline {
	outline: 1px dotted red;
}

.unstyled {
	color: inherit;
	text-decoration: inherit;
	cursor: inherit;
	&:active,
	&:focus {
		outline: none;
	}
	&:hover {
		text-decoration: underline !important;
		color: inherit;
	}
}

.pointer {
	cursor: pointer !important;
}

.text-small {
	font-size: 0.8em;
}

.mb-0 {
	margin-bottom: 0;
}

.th-no-border {
	/* border-width: 0px !important; */
	border-left-width: 0px !important;
	border-right-width: 0px !important;
	border-bottom-color: $blue !important;
	vertical-align:middle !important;
}
.th-border-top {
	border-top-color: $blue;
}

.toast-container {
	margin-top: 25%;
}

.flex-half-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	padding-left: 2em;
	padding-right: 2em;
	padding-top: 8px;
	padding-bottom: 8px;
}

.flex-half-cell {
	width: 50%;
}

.flex-three-cell {
	width: 33%;
	text-align: center;
}

.navy-thin-line {
	height: 1px;
	background-color: #ddd;
	margin-left: 1.5em;
	margin-right: 1.5em;
}

.remove-diagnosis {
	padding: 0;
	margin: 0;
	border: 1px solid $navy;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: white;

	&:hover {
		background-color: $navy;
		color: white;
	}
}

.remove-procedure {
	padding: 0;
	margin: 0;
	border: 1px solid #ff0000;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: white;

	&:hover {
		background-color: #ff0000;
		color: white;
	}
}

.arrow-right {
	border: solid $navy;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	margin-bottom: 2px;
	margin-left: 2px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.text-grey-light {
	color: $textGrey;
	font-family: 'Gordita Light';
}

.text-procedure {
	color: #ff0000;
}

.title-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 0.2em;
}

.filter-grey{
	filter: invert(66%) sepia(6%) saturate(173%) hue-rotate(190deg) brightness(89%) contrast(85%);
}

.time-picker {
	margin-left: 1em;
	padding-left: 1em;
    height: 3.0em;
    border-width: 1px;
    border-color: $navy;
    border-radius: 24px;
}

.scroll-container {
	position:absolute;
    width: 42.5em;
    height:80%;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-left: 1.5em;
	margin-top: 2em;
}

.scroll-container::-webkit-scrollbar {
	width: 5px; /* Set the width of the scrollbar */
	background: transparent;
	/* position: absolute; */
 }

 .scroll-container::-webkit-scrollbar-thumb {
	background-color: $navy; /* Set the color of the thumb */
 }

.progress-note-container {
	border-width: 2px;
	border-style: solid;
	border-color: $navy;
	border-radius: 8px;
	margin-bottom: 1em;
	width: 100%;
	min-height: 5em;
	padding: 0.5em;
}

.progress-note-container-delete {
	border-width: 2px;
	border-style: solid;
	border-color: $textGrey;
	border-radius: 8px;
	margin-bottom: 1em;
	width: 100%;
	min-height: 5em;
	padding: 0.5em;
}

.progress-note-bold {
	font-family: 'Gordita Bold';
	font-size: 12px;
	color: black !important;
}

.progress-note-bold-delete {
	font-family: 'Gordita Bold';
	font-size: 12px;
	color: $textGrey !important;
}

.progress-note-link {
	text-decoration: underline;
	font-family: 'Gordita Bold';
}

.panel-title {
    font-size: 14px;
    font-family: 'Gordita Bold';
}

#scrollable-dropdown-menu .dropdown-menu {
	height: 10em;
	overflow-y: auto;
	margin-top: -12.5em;
  }

.error-message {
	color: #a94442;
	display: block;
	margin-top: 5px;
}

.dialog-content-box {
	position: relative;
	height:32rem;
	width: 100%;
}

.invoice-item-cell {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.invoice-code-label {
	border-width: 1px;
	border-color: $navy;
	border-style: solid;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.invoice-item-row {
	display: flex;
	flex-direction: row;
	align-items: baseline;
	gap: 1rem;
}

.invoice-details-container {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-left: 60%;
	margin-right: 4%;
}

.invoice-details-section {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding-bottom: 1rem;
	border-bottom-width: 1px;
	border-bottom-color: gray;
	border-bottom-style: solid;
}

.invoice-details-row {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	color: $navy;
	justify-content: space-between;
}

.view-invoice-modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.header-group {
	display: flex;
	flex-direction: column;
}

.dismiss-close-icon {
	color: $white;
	font-size: 20px;
	align-self: end;
	margin-right: 11%;
}

.icons-row {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.md-dialog-container {
	flex-direction: column;
	gap: 0.5rem;
}

#billing-dropdown-menu .dropdown-menu {
	max-height: 20rem;
	overflow-y: auto;
  }

.progress-note-header {
	margin-left: 0px;
	margin-right: 0px;
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.progress-note-header-child {
	display: flex;
	justify-content: flex-start;
	gap: 1rem;
}


.fc-toolbar h2 {
	font-size: 16px;
	padding-top: 0.5rem;
}

.nhi-container {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.nhi-container-left {
	width: 50%;
}

.nhi-container-right {
	width: 50%;
}

.name-row-container {
	display: flex;
	gap: 0.5rem;
}

.red-label {
	color: red !important;
}

.nhi-column {
	max-width: 10rem;
	text-wrap: wrap;
	word-wrap: break-word;
}

.nhi-label {
	text-wrap: wrap;
	word-wrap: break-word;
	max-width: 80%;
}

.progress-note-procedure {
		display: flex;
		flex-direction: row;
		gap: 1rem;
}

.fc-more-popover {
	max-height: 95%;
	overflow-y: auto;
}

.search-radio-label {
	color: $navy !important;
	font-family: 'Gordita Medium' !important;
	font-size: 16px;
}

.patient-table-text {
	color: $navy !important;
}

.red-text {
	color: red;
}

.text-area-break {
	white-space: pre-line;
	margin-top: -1.5rem;
}

.task-label-container {
  display: flex;
  flex-direction: row;
}

.task-label-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.task-list-patient {
	width: 15%;
	/* text-align: center; */
}

.task-list-assigned {
	width: 20%;
	/* text-align: center; */
}

.task-list-description {
	width: 30%;
	/* text-align: center; */
	
}

.task-list-status {
	width: 5%;
	/* text-align: center; */
}

.task-list-delete {
	width: 3%;
	text-align: center;
}

.task-list-due {
	width: 7%;
	/* text-align: center; */
}

.task-list-label {
	width: 12%;
	/* text-align: center; */
}

.task-list-color {
	width: 2rem;
	height: 2rem;
	/* text-align: center; */
	border-radius: 0.5rem;
}

.task-done {
	text-decoration: line-through;
}

.button-footer {
	display: flex;
	gap: 1rem;
}

.nav-li {
	height: 2.5rem;
    line-height: 2.5rem;
}

.md-badge {
	z-index: 80 !important;
 }

 .sign-buttons-group {
 	display: flex;
 	flex-direction: row;
 	gap: 1rem;
	width: 100%;
	margin-left: 1rem;
 }

 .sign-buttons-container {
 	display: flex;
 	flex-direction: row;
	justify-content:space-between;
 }

 .header-left-align > thead > tr > th {
	text-align: left;
}

.lvl-over {
	/* applied to a drop target when a draggable object is over it */
	border: 2px dashed black !important;
}

.lvl-target {
	/* applied to all drop targets when a drag operation begins, used to indicate drop targets on a page */
	background-color: #ddd;
	opacity: .5;
}

[draggable] {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	/* Required to make elements draggable in old WebKit */
	-khtml-user-drag: element;
	-webkit-user-drag: element;
	cursor: move;
	border:1px solid black;
}

.peg {
	font-weight: bold;
	border-width: 0px;
	border-radius: 4px;
	width: auto;
	height:3rem;
	margin: 10px;
	padding: 0.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #019CD2;
	color: white;
}

.slot {
	margin-right:2px;
	margin-top:2px;
	width:25px;
	height:25px;
    display:inline-block;
    border: none;
}

.grey {
	background-color: grey;
}

pre strong {
	font-size:12pt;
}

.modal-close {
	z-index: 1;
	position: absolute;
	top: 7rem;
}

.my-drop-zone {
	border: dotted 3px lightgray;
}

.nv-file-over {
	border: dotted 3px red;
}

/* Default class applied to drop zones on over */
.another-file-over-class {
	border: dotted 3px green;
}

.location-container {
	margin-top: 0.5rem;
	border-width: 1px;
	border-color: $navy;
	border-radius: 4px;
	border-style: solid;
	padding: 0.5rem;
	display: flex;
	flex-direction: column;
	color: $navy;
}

.popover-container {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}




div.header,div.footer
{
padding:0.5em;
color:white;
background-color:gray;
/*color:green;*/
clear:left;
}




div.printControls
{
	background-color:#edf0ec;
	height:100px;
	padding: 5px;
	bottom:0;
	display: flex;
    flex-direction: row;
    gap: 1rem;
}

#labelImageDiv
{
	background-color: #b2b2b2;
	padding: 5px;
	margin: 0px;
}

#printDiv
{
	float:right;
	width:126px;
}


#printersDiv
{
	float:left;
}

.fc-bgevent {
	opacity: 1 !important;
}
